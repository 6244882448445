<template>
  <div>
    <el-carousel height="400px">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <div class="banner-list" :style="item.bgColor">
          <img class="banner-img" v-lazy="item.img"/>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="coupon-list w-1200 mt-2">
      <div class="coupon-item" :class="item.isReceive == 0?'':'not-user'" v-for="item in receiveCouponList"
           :key="item.id">
        <div class="coupon-price">
          ￥<span>{{item.value}}</span>
        </div>
        <div class="coupon-name">{{item.name}}</div>
        <div class="receive-num">
          <div class="number">每人限领<span>1</span>张</div>
          <div class="text">满{{item.minAmount}}可用</div>
        </div>
        <div class="receive-total">
          <div class="range">
            <span :style="'width:'+item.range+'%'"></span>
          </div>
          <div>已抢{{item.range}}%</div>
        </div>
        <div class="circle-box">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
        </div>
        <div class="receive-box" v-if="item.isReceive == 0" @click="receiveCouponHandle(item)">立即领取</div>
        <div class="receive-box" v-else>无法领取</div>
      </div>
    </div>
    <el-pagination class="w-1200"
      :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { receiveCouponCenterUrl, userReceiveCouponUrl } from '@/api/index'

export default {
  name: 'ReceiveCoupon',
  data () {
    return {
      bannerList: [
        {
          id: '1',
          img: 'https://java.bizpower.com/web/static/img/voucher_center_web.png',
          bgColor: 'background-color:#766bba'
        }, {
          id: '',
          img: 'https://uploadjava.bizpower.com/image/4d/4b/4d4b654a6a9f4b3ad4eaab0ae63a35f4.jpg',
          bgColor: 'background-color:#C81623'
        },
      ],
      queryInfo: {
        pageSize: 10,
        page: 1,
        field: '',
        desc: '',
      },
      receiveCouponList: [],
      total: 0,
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getReceiveCouponList()
    },
    // 获取可领取优惠券列表
    async getReceiveCouponList () {
      const { data: res } = await this.$http.get(receiveCouponCenterUrl, { params: this.queryInfo })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.receiveCouponList = res.data.list
      this.receiveCouponList.forEach(item => {
        item.range = ((item.total - item.remain) / item.total * 100).toFixed(2)
      })
      this.total = res.data.total
    },
    // 领取优惠券
    async receiveCouponHandle (couponInfo) {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        this.$router.push('/login')
        return false
      } else {
        if (couponInfo.isReceive == 0) {
          const { data: res } = await this.$http.put(userReceiveCouponUrl + couponInfo.id)
          console.log(res)
          if (res.code !== 200) return this.$message.error(res.msg)
          ++couponInfo.counts
          this.$message.success('领取成功，快去挑选商品吧')
          if (couponInfo.counts >= couponInfo.everyNums) {
            couponInfo.isReceive = 1
          }
        }
      }
    },
    // 分页页码显示条数
    handleSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.queryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getReceiveCouponList()
    },
    // 页面切换事件
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      // 重新获取数据并显示
      this.getReceiveCouponList()
    },
  },
}
</script>

<style lang="less" scoped>
  .banner-list {
    height: 400px;
    width: 100%;
    position: relative;

    .banner-img {
      width: 1200px;
      height: 400px;
      position: absolute;
      left: 50%;
      margin-left: -600px;
    }
  }

  .coupon-list {
    display: flex;
    justify-content: start;
    flex-flow: row wrap;

    .coupon-item {
      padding: 5px 10px 10px 20px;
      background-color: #F1F1F1;
      width: 255px;
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .coupon-price {

        font-size: 26px;
        color: #EF001C;
        font-weight: bold;
        line-height: 60px;
        width: 100px;

        span {
          font-size: 40px;
        }
      }

      .coupon-name {
        font-size: 14px;
        color: #333333;
        line-height: 26px;
      }

      .receive-num {
        display: flex;

        .text {
          margin-left: 20px;
          font-size: 14px;
          color: #999999;
          line-height: 26px;
        }

        .number {
          font-size: 14px;
          color: #666666;
          line-height: 26px;

          span {
            color: #EF001C;
            margin: 0 2px;
            font-size: 18px;
            font-weight: bold;
          }
        }

      }

      .receive-total {
        font-size: 14px;
        color: #999999;
        display: flex;
        height: 30px;
        line-height: 30px;

        .range {
          position: relative;
          width: 110px;
          background-color: #eeeeee;
          height: 6px;
          border-radius: 4px;
          margin-top: 12px;
          margin-right: 10px;

          span {
            position: absolute;
            background-color: #EF001C;
            border-radius: 4px;
            height: 6px;
            display: block;
            left: 0;
            top: 0;
          }
        }
      }

      .circle-box {
        position: absolute;
        right: 46px;
        top: 3px;

        .circle {
          width: 10px;
          height: 10px;
          background-color: #EF001C;
          border-radius: 50%;
          margin: 5px 0;
        }
      }

      .receive-box {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #EF001C;
        padding: 28px 16px;
        font-size: 14px;
        color: #ffffff;
        width: 20px;
        text-align: center;
        line-height: 24px;
        height: 107px;
        cursor: pointer;
      }
    }

    .coupon-item.not-user {
      .circle-box {
        .circle {
          background-color: #aaaaaa;
        }
      }

      .receive-box {
        background-color: #aaaaaa;
      }
    }
  }
</style>
